import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory, useParams, withRouter } from "react-router-dom";
import "moment/min/locales";
import { Container, Grid } from "@material-ui/core";
import Layout from "../../Layout";
import "../../../styles/index.css";
import styles from "../../../styles/viewer.module.css";
import PatientData from "../PatientData";
// import ReferralData from './ReferralData';
// import ReferralDetailTable from './ReferralDetailTable';
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "../theme";
import CircularProgress from "@material-ui/core/CircularProgress";
import useReferrals from "./useReferrals";
import FhirReferralData from "./FhirReferralData";
import FhirReferralDetailTable from "./FhirReferralDetailTable";
import {
  FhirReferralActionButton,
  FhirReferralActions,
} from "./FhirReferralActions";
import TextDialog from "./TextDialog";
import FhirReferralNotesTable from "./FhirReferralNotes";
import FhirReferralStates from "../../../services/FhirReferralStates";
// import { ReferralActionButton, ReferralActions } from './ReferralActions';
// import ReferralStates, {
//   isReferredOrganization,
//   isReferringOrganization
// } from '../../../services/ReferralStates';
// import { patientDataStore } from '../../../store/PatientDataStore';
// import { PatientProvisioningDialog } from '../PatientProvisioningDialog';
import auth from "../../../utils/auth";
// import { convertReferralToDisplayPatient } from '../../../utils';
// import ConfirmationDialog from './ConfirmationDialog';
// import { NotFoundException } from '../../../utils/exceptions';
// import ReferralDocumentSelectionDialogContainer
//   from './ReferralDocumentSelectionDialogContainer';
// import { ReferralUtils } from '../../../services';
import FhirReferralDocuments from "./FhirReferralDocuments";
import FhirReferralDocumentSelectionDialogContainer from "./FhirReferralDocumentSelectionDialogContainer";
import { checkAclValidation } from "../../../utils/permissions/permission.utils";
import mainAcls from "../../../utils/permissions/mainAcls";
import { AuthContext } from "../../../AuthProvider";
import useUserContextData from "../../../hooks/useUserContextData";
import { patientDataStore } from "../../../store/PatientDataStore";

// const API = mockReferrals ? MockReferralsAPI : ReferralsAPI;

const FhirReferralDetail = () => {
  // const history = useHistory()
  const { patientId, referralId } = useParams();
  const [patientInfo, setPatientInfo] = useState(null);
  // const [referral, setReferral] = useState(null);
  const [error, setError] = useState("");
  // const [rejectCommentDialogOpen, setRejectCommentDialogOpen] = useState(false);
  const token = useContext(AuthContext);
  const [noteDialogOpen, setNoteDialogOpen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  // const [dialogPatientData, setDialogPatientData] = useState({});
  // const [isProvisionDialogOpen, setProvisionDialogOpen] = useState(false);
  // const [dialogTitle, setDialogTitle] = useState('Add Referral Patient to your Organization?');
  // const [dialogShowOnlyOkButton, setDialogShowOnlyOkButton] = useState(false);
  // const [isWithdrawDialogOpen, setWithdrawDialogOpen] = useState(false);
  const [isReferralDocumentDialogOpen, setReferralDocumentDialogOpen] =
    useState(false);

  const { mrnCodingSystem } = useUserContextData();

  const {
    referral,
    patient,
    organizations,
    notes,
    hasNotes,
    canAddNote,
    referralDocuments,
    hasReferralDocuments,
    patientDocuments,
    hasPatientDocuments,
    canAddDocument,
    fetchReferralDocuments,
    fetchPatientDocuments,
    linkDocument,
    unlinkDocument,
    manageCreateNote,
    canAccept,
    acceptReferral,
    putOnHoldReferral,
    canReject,
    rejectReferral,
    canComplete,
    completeReferral,
    error: referralsError,
  } = useReferrals({
    patientId,
    individual: true,
    referralId: referralId,
  });

  const getPatientData = async () => {
    const data = await patientDataStore.getPatientData(
      patientId,
      mrnCodingSystem
    );
    if (data) {
      setPatientInfo(data);
    }
  };

  useEffect(() => {
    if (patient && patient?.id && mrnCodingSystem) {
      getPatientData(patientId, mrnCodingSystem);
    }
  }, [patient, mrnCodingSystem]);

  const openNoteDialog = () => {
    setNoteDialogOpen(true);
  };

  const closeNoteDialog = () => {
    setNoteDialogOpen(false);
  };

  const addNote = async (noteText) => {
    try {
      await manageCreateNote(noteText, new Date());
      closeNoteDialog();
      // setReferral(await loadReferral());
    } catch (error) {
      return error.message;
    }
  };

  // const acceptReferral = async () => {
  //   try {
  //     await API.acceptReferral(referralId);
  //     setReferral(await loadReferral());
  //   }
  //   catch (error) {
  //     setError(error.message);
  //   }
  // };

  // const addressReferral = async () => {
  //   try {
  //     await API.addressReferral(referralId);
  //     setReferral(await loadReferral());
  //   }
  //   catch (error) {
  //     setError(error.message);
  //   }
  // };

  // const completeReferral = async () => {
  //   try {
  //     await API.completeReferral(referralId);
  //     setReferral(await loadReferral());
  //   }
  //   catch (error) {
  //     setError(error.message);
  //   }
  // };

  // const showReferralRejectCommentDialog = () => {
  //   setRejectCommentDialogOpen(true);
  // };

  // const closeReferralRejectCommentDialog = () => {
  //   setRejectCommentDialogOpen(false);
  // };

  // const rejectReferral = async (comment) => {
  //   try {
  //     await API.rejectReferral(referralId, comment);
  //     setReferral(await loadReferral());
  //     closeReferralRejectCommentDialog();
  //   }
  //   catch (error) {
  //     return error.message;
  //   }
  // };

  // const withdrawReferral = async () => {
  //   try {
  //     await API.withdrawReferral(referralId);
  //     if (patientId) {
  //       history.push(`/patient/search/${patientId}/info/1/referrals`);
  //     }
  //     else {
  //       history.push(`/referrals`);
  //     }
  //     closeWithdrawDialog();
  //   }
  //   catch (error) {
  //     return error.message;
  //   }
  // };

  // const handleProvisionDialogSave = async (patientData) => {
  //   try {
  //     const { patientID, referral: provisionedReferral } =
  //       await ReferralsAPI.provisionReferralPatient(auth, referral)
  //     ;
  //     patientData.id = patientID;
  //     setPatientInfo({...patientData});
  //     setReferral(provisionedReferral);
  //     closeProvisionDialog();
  //   }
  //   catch (error) {
  //     return error.message;
  //   }
  // }

  // const closeProvisionDialog = () => {
  //   setProvisionDialogOpen(false);
  // }

  // const closeWithdrawDialog = () => {
  //   setWithdrawDialogOpen(false);
  // }

  // const showWithdrawDialog = () => {
  //   setWithdrawDialogOpen(true);
  // }

  const showReferralDocumentsDialog = () => {
    setReferralDocumentDialogOpen(true);
  };

  const hideReferralDocumentsDialog = () => {
    setReferralDocumentDialogOpen(false);
  };

  const handleDocumentLinked = async (docRefs) => {
    for (let index = 0; index < docRefs.length; index++) {
      const docRef = docRefs[index];
      try {
        const response = await linkDocument(docRef, referral);
        console.log("DEBUG linkedReferral: ", response);
      } catch (error) {
        return error.message;
      }
    }
    fetchPatientDocuments();
    fetchReferralDocuments();
  };

  const handleDocumentUploaded = async () => {
    fetchPatientDocuments();
    fetchReferralDocuments();
  };

  const handleDocumentDelete = async (documentId) => {
    console.log(
      `DEBUG Deleting Document with id ${documentId} for referral with id ${referral?.id}`
    );
    const result = await unlinkDocument(documentId, referral?.id);
    await fetchReferralDocuments();
  };

  return (
    <Fragment>
      <TextDialog
        open={noteDialogOpen}
        title="Add Note"
        handleSave={addNote}
        handleClose={closeNoteDialog}
      />
      {isReferralDocumentDialogOpen ? (
        <FhirReferralDocumentSelectionDialogContainer
          open={isReferralDocumentDialogOpen}
          handleSave={handleDocumentLinked}
          handleDocumentUploaded={handleDocumentUploaded}
          handleClose={hideReferralDocumentsDialog}
          patient={patient}
          referral={referral}
          referralDocuments={referralDocuments}
          patientDocuments={patientDocuments}
        />
      ) : null}
      {/* <TextDialog
        open={rejectCommentDialogOpen} title="Rejection Comment"
        handleSave={rejectReferral} handleClose={closeReferralRejectCommentDialog}
      />
      <PatientProvisioningDialog
        patientData={dialogPatientData}
        open={isProvisionDialogOpen}
        title={dialogTitle}
        handleSave={handleProvisionDialogSave}
        handleClose={closeProvisionDialog}
        showOnlyOkButton={dialogShowOnlyOkButton}
      />
      <ConfirmationDialog
        open={isWithdrawDialogOpen}
        title="Withdraw Referral?"
        text="The referral will be deleted. This action cannot be reversed. Are you sure?"
        handleSave={withdrawReferral}
        handleClose={closeWithdrawDialog}
      />
       */}
      {/* <Layout referral={true} patientData={patient}> */}
      <Layout referral={true} patientData={patientInfo}>
        {checkAclValidation({
          acls: [mainAcls.MAIN.REFERRAL.VIEW],
        }) ? (
          <Grid item xs={12}>
            <Container className="container-documents">
              <div className={styles.documentViewer}>
                {error && (
                  <ThemeProvider theme={theme}>
                    <Container className={styles.errorContainer}>
                      {error}
                    </Container>
                  </ThemeProvider>
                )}
                {!error && referral && (
                  <Fragment>
                    <PatientData patientData={patientInfo} />
                    <FhirReferralActions>
                      {checkAclValidation({
                        acls: [mainAcls.MAIN.REFERRAL.EDIT],
                      }) ? (
                        <>
                          <FhirReferralActionButton
                            text="Add Note"
                            action={openNoteDialog}
                            canShow={canAddNote}
                            referral={referral}
                            patient={patient}
                          />
                          <FhirReferralActionButton
                            text="Add Document"
                            action={showReferralDocumentsDialog}
                            canShow={canAddDocument}
                            referral={referral}
                            patient={patient}
                          />
                          <FhirReferralActionButton
                            text="Accept"
                            action={acceptReferral}
                            canShow={canAccept}
                            referral={referral}
                            patient={patient}
                          />
                          <FhirReferralActionButton
                            text="Put on Hold"
                            action={putOnHoldReferral}
                            canShow={canAccept}
                            referral={referral}
                            patient={patient}
                          />
                          <FhirReferralActionButton
                            text="Reject"
                            action={rejectReferral}
                            canShow={canReject}
                            referral={referral}
                            patient={patient}
                          />
                          <FhirReferralActionButton
                            text="Complete"
                            action={completeReferral}
                            canShow={canComplete}
                            referral={referral}
                            patient={patient}
                          />
                        </>
                      ) : null}

                      {/* 
                      <ReferralActionButton
                        text="Withdraw Referral" action={showWithdrawDialog}
                        canShow={ReferralStates.canWithdraw} referral={referral}
                        patientInfo={patientInfo}
                      />
                      
                      <ReferralActionButton
                        text="Address Referral" action={addressReferral}
                        canShow={ReferralStates.canAddress} referral={referral}
                        patientInfo={patientInfo}
                      />
                      <ReferralActionButton
                        text="Add Patient to your Org"
                        action={showProvisionDialogIfApplicable}
                        canShow={ReferralStates.canProvision} referral={referral}
                        color="secondary"
                        patientInfo={patientInfo} */}
                    </FhirReferralActions>
                    <FhirReferralData
                      referral={referral}
                      organizations={organizations}
                    />
                    <FhirReferralDetailTable referral={referral} />
                    {hasNotes() && <FhirReferralNotesTable notes={notes} />}
                    <FhirReferralDocuments
                      referral={referral}
                      documents={referralDocuments}
                      patientId={patientId}
                      onDocumentDelete={handleDocumentDelete}
                    />
                  </Fragment>
                )}
                {!error && !referral && (
                  <ThemeProvider theme={theme}>
                    <div>
                      <CircularProgress />
                    </div>
                  </ThemeProvider>
                )}
              </div>
            </Container>
          </Grid>
        ) : null}
      </Layout>
    </Fragment>
  );
};

export default withRouter(FhirReferralDetail);
